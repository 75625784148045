<template>
  <div class="video-detail-container">
    <components-separate title="视频播放" desc="VIDEO PLAYER" />
    <video class="my-video" src="http://back.hls-pharm.com/video/sp.mp4" autoplay controls />
  </div>
</template>

<script>
import ComponentsSeparate from '@/components/Separate/index.vue'

export default {
  name: 'VideoDetail',
  components: { ComponentsSeparate },
  created () {
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.video-detail-container {
  width: $content-width;
  .my-video {
    width: 100%;
  }
}
</style>
